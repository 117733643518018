var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"btn-group\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":48}}}) : helper)))
    + "\">\n    <a href=\"/conversations/new/"
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":46}}}) : helper)))
    + "\" class=\"btn btn-sm btn-default\">\n        <i class=\"fa fa-envelope\"></i>\n    </a>\n    <button class=\"user_observe_btn btn btn-sm "
    + alias4(((helper = (helper = lookupProperty(helpers,"observe_class") || (depth0 != null ? lookupProperty(depth0,"observe_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"observe_class","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":66}}}) : helper)))
    + "\">\n        <i class=\"fa fa-eye\"></i>\n    </button>\n    <button class=\"user_block_btn btn btn-sm "
    + alias4(((helper = (helper = lookupProperty(helpers,"block_class") || (depth0 != null ? lookupProperty(depth0,"block_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"block_class","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":62}}}) : helper)))
    + "\">\n        <i class=\"fa fa-ban\"></i>\n    </button>\n</div>\n";
},"useData":true});