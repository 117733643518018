var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-default entry entry_reply\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":62}}}) : helper)))
    + "\">\n    <a name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":21}}}) : helper)))
    + "\"></a>\n\n    <div class=\"entry_avatar\">\n        <img src=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"avatar_url") : stack1), depth0))
    + "\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n    </div>\n\n    <div class=\"panel-heading entry_header\" data-hover=\"user_widget\" data-user=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n        <a href=\"/u/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" class=\"entry_author\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>\n\n        <span class=\"pull-right\">\n            <i class=\"fa fa-clock-o\"></i>\n\n            <a href=\"/e/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":32}}}) : helper)))
    + "\">\n                <time pubdate title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"created_at") || (depth0 != null ? lookupProperty(depth0,"created_at") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"created_at","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":53}}}) : helper)))
    + "\">chwilę temu</time>\n            </a>\n\n            <span class=\"voting\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":50}}}) : helper)))
    + "\" state=\"none\" data-type=\"entry-reply\">\n                <button type=\"button\" class=\"btn btn-light btn-xs vote-btn-up\">\n                    <i class=\"fa fa-arrow-up vote-up\"></i>\n                    <span class=\"count\">0</span>\n                </button>\n                <button type=\"button\" class=\"btn btn-light btn-xs vote-btn-down\">\n                    <i class=\"fa fa-arrow-down vote-down\"></i>\n                    <span class=\"count\">0</span>\n                </button>\n            </span>\n        </span>\n    </div>\n\n    <div class=\"entry_text md\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":31,"column":31},"end":{"line":31,"column":43}}}) : helper))) != null ? stack1 : "")
    + "</div>\n\n    <div class=\"entry_actions pull-right\">\n        <i class=\"fa fa-star-o action_link save_entry\" title=\"zapisz\"></i>\n        <a class=\"entry_reply_link action_link\">odpowiedz</a>\n        <a href=\"/e/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":28}}}) : helper)))
    + "\">#</a>\n    </div>\n</div>\n";
},"useData":true});